/**
 *
 * StaticMap
 *
 */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useFormatMessage } from '@artemis/integrations/contentful/utils';

const MapWrapper = styled.a`
  position: relative;
`;

const MerchantMap = styled.img.attrs({ alt: '' })`
  width: 100%;
`;

const convertHashHex = hex => hex.replace('#', '0x');

/**
 * StaticMap component description
 */
const StaticMap = ({
  lat,
  lon,
  zoom = 16,
  className,
  color = 'red',
  width = 552,
  height = 400,
}) => {
  const mapLabel = useFormatMessage({ entry: 'global.googleMapsLink' });
  const googleUrl = `https://maps.google.com/?q=${lat},${lon}&ll=${lat},${lon}&z=${zoom}`;
  const convertedColor = color.startsWith('#') ? convertHashHex(color) : color;
  return (
    <MapWrapper
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      href={googleUrl}
      aria-label={mapLabel}
    >
      <MerchantMap
        src={`https://maps.googleapis.com/maps/api/staticmap?
        center=${lat},${lon}
        &zoom=${zoom}
        &size=${width}x${height}&scale=2
        &markers=color:${convertedColor}%7C${lat},${lon}
        &key=${process.env.RT_GOOGLE_MAPS_API_KEY}`}
      />
    </MapWrapper>
  );
};

StaticMap.propTypes = {
  lat: PropTypes.number,
  lon: PropTypes.number,
  zoom: PropTypes.number,
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default StaticMap;
