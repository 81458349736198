import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  getMerchantStreetAddress,
  getMerchantLatLng,
  getMerchantOpenHours,
  getMerchantPickupInstructions,
  getMerchantPhone,
  getMerchantEmail,
} from '@artemis/store/merchant/selectors';
import CustomModal from '@artemis/components/Modal';
import StaticMap from '@artemis/components/StaticMap';
import ResponsiveImage from '@artemis/components/ResponsiveImage';

import {
  FormattedMessage,
  useFormatMessage,
} from '@artemis/integrations/contentful/utils';

const MoreInfoModal = styled(CustomModal)`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.palette.background.card};
  :focus {
    outline: none;
  }
  ${({ theme }) => theme.isTablet`
    width: 460px;
    max-height: 700px;
    margin: 0 auto;
    margin: 50vh auto;
    transform: translateY(-50%);
  `}
`;

const Container = styled.div`
  border-radius: 4pt;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  ${({ theme }) => theme.noScrollbars};
  ${({ theme }) => theme.scrollShadows.vertical}
`;

const StoreInfoCopy = styled.div`
  padding: 16px 45px;
  > div:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const CloseModal = styled.button`
  background: rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(4px);
  box-shadow: ${props => props.theme.shadows.shadow1};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  top: 24px;
  left: 24px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  z-index: 1;
`;

const Icon = styled(ResponsiveImage)`
  height: 24px;
  width: 24px;
`;

const HoursContainer = styled.div`
  width: 100%;
`;
const HoursRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const StyledLink = styled.a`
  color: ${({ theme }) => theme.palette.primary};
  text-decoration: none;
`;

const Durations = styled.div``;

const ZOOM = 16;

const ModalHeading = styled.h3`
  font-weight: ${props => props.theme.typography.fontWeightBold};
`;

const MoreInfoSubheading = styled.h6`
  font-weight: ${props => props.theme.typography.fontWeightBold};
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 8px;

  a {
    ${props => props.theme.typography.caption};
    ${props => props.theme.typography.bodySmall};

    align-items: center;
    border: 1px solid ${props => props.theme.palette.grey[300]};
    border-radius: 4pt;
    color: ${props => props.theme.palette.common.black};
    display: flex;
    flex-grow: 1;
    justify-content: center;
    padding: 10px;
    text-decoration: none;

    > *:not(:last-child) {
      margin-right: 10px;
    }

    ${props => props.theme.isTablet`
      max-width: 50%;
    `}
  }
  a:not(:last-child) {
    margin-right: 8px;
  }
`;

const MoreInfoBody = styled.p`
  color: ${props => props.theme.palette.grey.UI1};
  ${props => props.theme.typography.bodySmall};
`;

const ContactTitle = styled.p`
  ${props => props.theme.typography.caption};
  font-weight: ${props => props.theme.typography.fontWeightMedium};
`;

const ContactDetails = styled.p`
  ${props => props.theme.typography.caption};
  color: ${props => props.theme.palette.grey[600]};
  font-weight: ${props => props.theme.typography.fontWeightRegular};
`;

const Modal = ({ isOpen, onRequestClose }) => {
  const theme = useContext(ThemeContext);
  const streetAddress = useSelector(getMerchantStreetAddress);
  const openHours = useSelector(getMerchantOpenHours);
  const loc = useSelector(getMerchantLatLng);
  const googleUrl = `https://maps.google.com/?q=${loc.latitude},${loc.longitude}&ll=${loc.latitude},${loc.longitude}&z=${ZOOM}`;
  const instructions = useSelector(getMerchantPickupInstructions);
  const merchantPhone = useSelector(getMerchantPhone);
  const merchantEmail = useSelector(getMerchantEmail);

  return (
    <>
      <MoreInfoModal
        className="store-info-modal-content"
        overlayClassName="store-info-modal-overlay"
        isOpen={isOpen}
        contentLabel={useFormatMessage({ entry: 'menu.store_info.more_info' })}
        onRequestClose={onRequestClose}
      >
        <Container>
          <CloseModal data-testid="close-store-info" onClick={onRequestClose}>
            <Icon id="close.svg.img" />
          </CloseModal>
          <StaticMap
            lat={loc.latitude}
            lon={loc.longitude}
            color={theme.palette.primary}
            zoom={13}
            width={600}
            height={300}
          />
          <StoreInfoCopy>
            <div>
              <ModalHeading>
                <FormattedMessage entry="menu.store_info.more_info_title" />
              </ModalHeading>
              <StyledLink
                href={googleUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {streetAddress}
              </StyledLink>
            </div>
            <div>
              <MoreInfoSubheading>
                <FormattedMessage entry="menu.store_info.more_info" />
              </MoreInfoSubheading>
              <MoreInfoBody>{instructions}</MoreInfoBody>
            </div>
            {(merchantPhone || merchantEmail) && (
              <div>
                <MoreInfoSubheading>
                  <FormattedMessage entry="menu.store_info.contact_info" />
                </MoreInfoSubheading>
                <ButtonGroup>
                  {merchantPhone && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`tel:${merchantPhone}`}
                    >
                      <Icon id="phone.svg.img" />
                      <div>
                        <ContactTitle>
                          <FormattedMessage entry="global.callRestaurant" />
                        </ContactTitle>
                        <ContactDetails>{merchantPhone}</ContactDetails>
                      </div>
                    </a>
                  )}
                  {merchantEmail && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`mailto:${merchantEmail}`}
                    >
                      <Icon id="email.svg.img" />
                      <div>
                        <ContactTitle>
                          <FormattedMessage entry="global.emailRestaurant" />
                        </ContactTitle>
                        <ContactDetails>{merchantEmail}</ContactDetails>
                      </div>
                    </a>
                  )}
                </ButtonGroup>
              </div>
            )}
            {openHours && (
              <HoursContainer>
                <MoreInfoSubheading>
                  <FormattedMessage entry="menu.store_info.hours" />
                </MoreInfoSubheading>
                {openHours.map(openHour => (
                  <HoursRow key={openHour.dayOfWeek}>
                    <MoreInfoBody>{openHour.dayOfWeek}</MoreInfoBody>
                    <Durations>
                      {openHour.durationList.map(hours => (
                        <MoreInfoBody key={`hrs_${hours}`}>
                          {hours}
                        </MoreInfoBody>
                      ))}
                    </Durations>
                  </HoursRow>
                ))}
              </HoursContainer>
            )}
          </StoreInfoCopy>
        </Container>
      </MoreInfoModal>
    </>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
};

export default Modal;
